@import url('https://fonts.googleapis.com/css2?family=Dosis:wght@200;400&display=swap');
.App-title{
  text-align: center;
  font-family: 'Dosis', Courier, monospace;
}
.name {
  color: white;
  font-size: 2em;
  font-family: 'Dosis', Courier, monospace;
}
.App-header {
  background-color: #282c34;
  display: flex;
  justify-content: center;
  align-items:center;
  min-height: 45vh;
  font-size: calc(10px + 2vmin);
  color: white;
}
.social-network {
  color: white;
}
.social-network:hover {
  color: #f39c12;
}
.resume_btn {
  margin-top: 15px;
}
._job {
  font: 1.8em;
  font-family: 'Dosis', Courier, monospace;
}
.duties-title {
  font-weight: bold;
  font-family: 'Dosis', Courier, monospace;
}
.duties-desc {
  font-size: 20px;
  line-height: 1.7;
  font-family: 'Dosis', Courier, monospace;
}
.icon {
  font-size: 1em;
}
.skill-icon {
  text-align: center;
  overflow: auto;
  padding-bottom: 35px;
}
._duties-intro {
  padding-top: 80px;
  padding-right: 80px;
  padding-left: 80px;
  font-family: 'Dosis', Courier, monospace;
}
.about-intro-title {
  font-weight: bold;
  font-size: 30px;
}
.about-intro {
  padding-top: 20px;
  padding-right: 80px;
  padding-left: 80px;
  font-size: 20px;
  text-align: center;
  font-family: 'Dosis', Courier, monospace;
}
.project-table {
  padding-top: 20px;
  padding-right: 80px;
  padding-left: 80px;
  font-size: 20px;
  width: 100%;
  overflow: auto;
  font-family: 'Dosis', Courier, monospace;
}
.col-about{
  text-align: center;
  font-family: 'Dosis', Courier, monospace;
}
._about{
  font-size: 30px;
  font-family: 'Dosis', Courier, monospace;
}
footer {
  bottom: 0;
  right: 0;
  left: 0;
  width: 100%;
  padding-right: 80px;
  padding-left: 80px;
  padding-top: 40px;
  padding-bottom: 10px;
  font-size: 20px;
  font-family: 'Dosis', Courier, monospace;
}